import * as Sentry from '@sentry/sveltekit'
import _ from 'lodash'

import {
	PUBLIC_SENTRY_DSN as sentryDsn, PUBLIC_DEPLOY_ENV as DEPLOY_ENV,
	PUBLIC_GITBRANCH as gitBranch, PUBLIC_GITHASHPLAIN as gitHash,
	PUBLIC_DOPPLER_CONFIG as dopplerConfig,
	PUBLIC_SENTRY_COMMIT_INFO as sentryCommit
} from '$env/static/public'

if (!_.isUndefined(sentryDsn) && sentryDsn !== 'undefined') {
	console.debug(`Enable sentry (client - hooks) ${sentryDsn} - ${DEPLOY_ENV} - ${dopplerConfig}`)
	Sentry.init({
		dsn: sentryDsn, // Sentry can find this from the environment
		release: `starbase-core@${sentryCommit}`,
		environment: DEPLOY_ENV,
		// For instance, initialize Session Replay:
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		ignoreErrors: [
			'TypeError: error loading dynamically imported module'
		],
		integrations: [
			Sentry.feedbackIntegration({
				colorScheme: 'system',
				autoInject: false
			})
		]
	})
	Sentry.getCurrentScope().setTags({
		gitBranch,
		gitHash,
		dopplerConfig,
		component: 'client-hooks'
	})
} else {
	console.debug('Sentry not enabled - client ')
}

export function myErrorHandler ({ error, event }) {
	Sentry.captureException(error, { event })
	console.error('An error occurred on the client side:', error, event)

	return {
		message: 'Whoops!',
		code: error.code ?? 'UNKNOWN'
	}
}

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler)
